<template>
  <main class="main page supporting contact newproject CMS_pages">
    <section class="section" id="contact">
      <h1 class="section__heading">Contact Us</h1>
      <form class="form--inline" @submit.prevent="sendContactUsEmail">
        <div class="form__content new_form_style">
          <p class="form__subheading">
            Questions, concerns, or just want to connect?
          </p>
          <div class="form__item form__item--text form__item--half">
            <label>First name</label>
            <input
              v-model="contactUs.name"
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              required
            >
          </div>
          <div class="form__item form__item--text form__item--half">
            <label>Email</label>
            <input
              v-model="contactUs.email"
              type="email"
              name="email"
              id="email"
              placeholder="Email address"
              required
            >
          </div>
          <div class="form__item form__item--text form__item--half">
            <label>Subject</label>
            <input
              v-model="contactUs.subject"
              type="text"
              name="subject"
              id="subject"
              placeholder="Subject"
            >
          </div>
          <div class="form__item form__item--text form__item--half">
            <label>Phone Number</label>
            <input
              v-model="contactUs.phone"
              type="text"
              name="phone"
              id="phone"
              placeholder="Phone number"
            >
          </div>
          <div class="form__item form__item--textarea">
            <label>Message</label>
            <textarea v-model="contactUs.body" required />
          </div>
          <div style="grid-column: span 12; text-align: right">
          <input
            type="submit"
            value="Submit &#x2192;"
            class="customGreenBTN"
          />
          </div>
        </div>
        <Loader :visible="sendingEmail" blocking />
      </form>
      <div class="text center contact-info">
        <p><a href="mailto:info@totree.co">info@totree.co</a> &middot; <a href="tel:9143404440">(914) 340-4440</a></p>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { httpsCallable } from 'firebase/functions'
import Loader from '@/components/Loader'

export default {
  data () {
    return {
      contactUs: {},
      sendingEmail: false
    }
  },
  computed: {
    ...mapState(['fbFunctions'])
  },
  components: {
    Loader
  },
  methods: {
    ...mapMutations(['setGlobalNotification', 'setGlobalError']),
    async sendContactUsEmail () {
      this.sendingEmail = true
      try {
        await httpsCallable(this.fbFunctions, 'sendgrid-contactUsEmail')(this.contactUs)
        this.setGlobalNotification('Email Sent')
        this.contactUs = {}
      } catch (err) {
        this.setGlobalError('Sorry, your email couldn\'t be delivered.')
      }
      this.sendingEmail = false
    }
  }
}
</script>
