<template>
  <main class="main page supporting contact">
    <section class="section" id="contact">
      <h1 class="section__heading">Terms and Conditions</h1>
        <Loader :visible="sendingEmail" blocking />
      <div class="text center contact-info">
      <div class="term-box">
         <h3>Eligibility</h3>
        <p>This contest is open to legal residents of the United States, aged 18 and above. Employees of toTree INC., its affiliates, subsidiaries, advertising and promotion agencies, and suppliers, and immediate family members and/or those living in the same household of each are not eligible to participate.</p>
       </div>
        <div class="term-box">
         <h3>Agreement to Rules</h3>
        <p>By participating, the contestant agrees to be fully unconditionally bound by these rules and represents and warrants that the contestant meets the eligibility requirements.</p>
       </div>
        <div class="term-box">
         <h3>Contest Period</h3>
        <p>Entries will be accepted online starting from 9/15/23 and ending 12/31/23. All entries must be received by 11:59 PM on 12/31/23.</p>
       </div>
        <div class="term-box">
         <h3>How to Enter</h3>
        <p>Participants can enter by purchasing furniture through the toTree.co website. Delivery and receipt of furniture is not required by 12/31/23 for eligibility. Any attempt by any participant to obtain more than the stated number of entries by using multiple/different email addresses, identities, or any other method will void that participant's entries and that participant may be disqualified.</p>
       </div>
        <div class="term-box">
         <h3>Prizes</h3>
        <p>One winner will receive $5,000 in cash or toTree furniture. No other prize substitution shall be permitted. The prize is non-transferable. Any and all prize-related expenses, including without limitation any and all federal, state, and/or local taxes, shall be the sole responsibility of the winner.</p>
       </div>
        <div class="term-box">
         <h3>Winner Selection and Notification</h3>
        <p>Winner will be selected by random drawing on January 1, 2024. Winner will be notified by email within five (5) days following selection. toTree shall have no liability for the winner's failure to receive notices. If the winner cannot be contacted, is ineligible, or fails to claim the prize within 90 days from the time the award notification was sent, the prize may be forfeited and an alternate winner selected.</p>
       </div>
        <div class="term-box">
         <h3>Rights Granted by Participants</h3>
        <p>By entering this contest, participants understand and agree that toTree INC. and its licensees, successors, and assigns shall have the right, where permitted by law, to print, publish, broadcast, distribute, and use in any media now known or hereafter developed, in perpetuity and throughout the World, without limitation, your entry, name, portrait, picture, voice, likeness, image, statements about the contest, and biographical information for news, publicity, information, trade, advertising, public relations, and promotional purposes without any further compensation, notice, review, or consent.</p>
       </div>
        <div class="term-box">
         <h3>Limitation of Liability</h3>
        <p>By entering, participants release and hold harmless toTree INC., its subsidiaries, affiliates, advertising and promotion agencies, partners, representatives, agents, successors, assigns, employees, officers, and directors from any liability, illness, injury, death, loss, litigation, claim, or damage that may occur, directly or indirectly, whether caused by negligence or not.</p>
       </div>
       <div class="term-box">
         <h3>Disputes</h3>
        <p>This contest is governed by the laws of Kings County, New York, without respect to its conflict of law doctrines. Participants agree to resolve any disputes through binding arbitration in Kings County.</p>
       </div>
       <div class="term-box">
         <h3>Privacy Policy</h3>
        <p>Information submitted with an entry is subject to the Privacy Policy stated on the toTree.co website.</p>
       </div>
       <div class="term-box">
         <h3>Sponsor</h3>
        <p>The sponsor of the contest is toTree INC., 153 Dekalb Avenue, Unit 3, Brooklyn, NY 11217.</p>
       </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Loader from '@/components/Loader'

export default {
  data () {
    return {
      TermAndConditions: {}
    }
  },
  computed: {
    ...mapState(['fbFunctions'])
  },
  components: {
    Loader
  },
  methods: {
    ...mapMutations(['setGlobalNotification', 'setGlobalError'])
  }
}
</script>
<style>
.term-box{
  margin-bottom:20px;
}
.term-box h3{
  font-size:20px;
  font-family: "GT Pressura Mono";
  font-size: 1.5rem;
  font-weight: 500;
}
.term-box p{
  text-transform: capitalize;
  color:#505050;
}
</style>
